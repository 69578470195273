import React, { useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useProjectDocs } from "../hooks/tasks";
import { useTaskDocuments } from "../services/task";
import DocumentThumbnail from "./DocumentThumbnail";
import RevisionModal from "./RevisionModal";
import { DocumentNode, Revision } from "../types";

interface jwtObject {
  id: string;
  jwt: string;
}

// const renderThumbnails = (
//   docTokenArray: jwtObject[],
//   handleDrawerClose: () => void
// ) => {
//   return docTokenArray.map((object) => (
//     <DocumentThumbnail
//       key={object.id}
//       object={object}
//       handleDrawerClose={handleDrawerClose}
//     />
//   ));
// };

// const DocumentThumbnail = ({
//   object,
//   handleDrawerClose,
// }: {
//   object: jwtObject;
//   handleDrawerClose: () => void;
// }) => {
//   const { taskID } = useParams();
//   const history = useHistory();

//   return (
//     <Grid
//       item
//       onClick={() => {
//         history.push(`/doc/${taskID}/${object.id}`);
//         handleDrawerClose();
//       }}
//     >
//       <img
//         src={`https://startautumn.com/documents/${object.id}/cover?width=400&jwt=${object.jwt}`}
//         alt=""
//       />
//     </Grid>
//   );
// };

export default function ({
  handleDrawerClose,
}: {
  handleDrawerClose: () => void;
}) {
  const docTokenArray: jwtObject[] | undefined = useProjectDocs();

  const history = useHistory();
  const { docID, taskID } = useParams();

  const [selectedDocument, setSelectedDocument] = useState<DocumentNode>();
  const [revisionVisible, setRevisionVisible] = useState(false);
  const documents = useTaskDocuments(taskID);

  async function handleRevisionSelect(revision: Revision) {
    handleDrawerClose();
    history.push(`/doc/${taskID}/${revision.URLID}`);
  }

  return (
    <div style={{ padding: 15 }}>
      <RevisionModal
        document={selectedDocument}
        open={revisionVisible}
        onOk={handleRevisionSelect}
        onClose={() => {
          setRevisionVisible(false);
        }}
      />
      {documents ? (
        <Grid container spacing={2} justify={"space-around"}>
          {documents.map((doc) => (
            <DocumentThumbnail
              key={doc.uid}
              document={doc}
              onClick={(doc) => {
                setSelectedDocument(doc);
                setRevisionVisible(true);
              }}
            />
          ))}
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
