import { useReducer } from "react";

interface AuthState {
  isLoading: boolean;
  userToken: string | null;
}
type Actions =
  | { type: "RESTORE_TOKEN"; token: string }
  | { type: "SIGN_IN"; token: string }
  | { type: "SIGN_OUT" };

function reducer(prevState: AuthState, action: Actions) {
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        ...prevState,
        userToken: action.token,
        isLoading: false,
      };
    case "SIGN_IN":
      return {
        ...prevState,
        userToken: action.token,
      };
    case "SIGN_OUT":
      return {
        ...prevState,
        userToken: null,
      };
  }
}

export function useAuthReducer(): [AuthState, React.Dispatch<Actions>] {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    userToken: null,
  } as AuthState);
  return [state, dispatch];
}
