import React, { useMemo } from "react";
import ApolloClient, { InMemoryCache } from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter as Router, Route, RouteProps } from "react-router-dom";
import Login from "./pages/Login";
import Tasks from "./pages/Tasks";
import Traveler from "./pages/Traveler";
import { useAuthReducer } from "./hooks/auth";
import { login } from "./services/auth";
import { AUTH_TOKEN } from "./constants";
import config from "./config";

interface AuthContextType {
  signIn: (
    userName: string,
    password: string
  ) => Promise<{
    errors?: unknown;
    data?: { access_token: string; code: number; message: string };
  }>;
  signOut: () => void;
}

const baseAuth: AuthContextType = {
  signIn: async () => ({}),
  signOut: () => {},
};

export const AuthContext = React.createContext(baseAuth);

const PrivateRoute = ({
  component,
  isAuthenticated,
  ...rest
}: RouteProps & { isAuthenticated: boolean }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated && component ? (
        React.createElement(component, {
          ...props,
        })
      ) : (
        <Login />
      )
    }
  />
);

function App() {
  const [{ userToken }, dispatch] = useAuthReducer();

  const authContext: AuthContextType = {
    signIn: async (userName, password) => {
      try {
        const { data } = await login(userName, password);

        localStorage.setItem(AUTH_TOKEN, data.access_token);
        dispatch({ type: "SIGN_IN", token: data.access_token });
        return { data };
      } catch (e) {
        return { errors: e };
      }
    },
    signOut: async () => {
      localStorage.removeItem(AUTH_TOKEN);
      dispatch({ type: "SIGN_OUT" });
    },
  };

  // new client object any time the token changes
  const client = useMemo(
    () =>
      new ApolloClient({
        uri: config.GRAPHQL,
        cache: new InMemoryCache(),
        request: (operation: {
          setContext: (arg0: { headers: { authorization: string } }) => void;
        }) => {
          operation.setContext({
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          });
        },
      }),
    [userToken]
  );

  // TODO move this to a route array file maybe?
  return (
    <ApolloProvider client={client}>
      <AuthContext.Provider value={authContext}>
        <Router>
          <PrivateRoute
            exact
            path={"/"}
            isAuthenticated={userToken !== null}
            component={Tasks}
          />
          <PrivateRoute
            exact
            path={"/doc/:taskID"}
            isAuthenticated={userToken !== null}
            component={Traveler}
          />
          <PrivateRoute
            exact
            path={"/doc/:taskID/:docID"}
            isAuthenticated={userToken !== null}
            component={Traveler}
          />
          <Route exact path={"/login"} component={Login} />
        </Router>
      </AuthContext.Provider>
    </ApolloProvider>
  );
}

export default App;
