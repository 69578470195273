import React, { useState } from "react";
import { TaskMessage, User } from "../types";
import moment from "moment";
import { useCurrentUser } from "../services/users";
import {
  IconButton,
  makeStyles,
  TextField,
  Avatar,
  Chip,
} from "@material-ui/core";
import { AlternateEmail, SendRounded } from "@material-ui/icons";
import { usersFullName } from "../helpers/users";
import UserSelectModal from "./UserModal";

interface props {
  onSubmit: (comment: TaskMessage) => void;
  users: User[] | undefined;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  chatContainer: {
    display: "flex",
    flex: 1,
    overflow: "auto",
    flexDirection: "column",
  },
  messageCardContainer: {
    margin: 20,
  },
  bottomMessageContainer: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: 20,
    backgroundColor: "#fff",
  },
  inline: {
    display: "inline",
  },
}));

export default function MessageEditor({ onSubmit, users }: props) {
  const { currentUser } = useCurrentUser();
  const [content, setContent] = useState<string>("");
  const [mentionedUsers, setMentionedUsers] = useState<User[]>();
  const [loading, setLoading] = useState(false);
  const [userSelectVisible, setUserSelectVisible] = useState(false);
  const classes = useStyles();

  async function handleSubmit() {
    setLoading(true);
    await (async () => {
      await onSubmit({
        content,
        author: currentUser.uid,
        createdAt: moment().unix(),
        mentions: mentionedUsers?.map(({ uid }) => uid) || [],
      });
    })();
    setLoading(false);

    setContent("");
    setMentionedUsers(undefined);
  }

  function removeUser(user: User) {
    setMentionedUsers(mentionedUsers?.filter((u) => u.uid !== user.uid));
  }

  function handleAddMentions(addedUsers: User[]) {
    const userLookup =
      mentionedUsers?.reduce((obj, user) => {
        obj[user.uid] = user;
        return obj;
      }, {} as { [key: string]: User }) || {};

    for (const user of addedUsers) {
      userLookup[user.uid] = user;
    }

    setMentionedUsers(Object.values(userLookup));
    setUserSelectVisible(false);
  }

  return (
    <div className={classes.bottomMessageContainer}>
      <UserSelectModal
        users={users}
        onSelect={handleAddMentions}
        visible={userSelectVisible}
        onCancel={() => {
          setUserSelectVisible(false);
        }}
      />
      <div style={{ display: "flex" }}>
        <IconButton>
          <AlternateEmail
            onClick={() => {
              setUserSelectVisible(true);
            }}
          />
        </IconButton>
        {mentionedUsers?.map((u) => (
          <Chip
            avatar={<Avatar alt={usersFullName(u)} src={u.profileURL} />}
            label={usersFullName(u)}
            onDelete={() => {
              removeUser(u);
            }}
            variant="outlined"
          />
        ))}
      </div>
      <div>
        <div style={{ display: "flex" }}>
          <TextField
            variant={"outlined"}
            placeholder={"Leave a message"}
            fullWidth={true}
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
          />
          <IconButton onClick={handleSubmit} disabled={loading}>
            <SendRounded />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
