import Axios from "axios";
import config from "../config";

export const login = async (email: string, password: string) => {
  const { data, errors } = await Axios.post(config.LOGIN, {
    email,
    password,
  });
  if (data) {
    return { data };
  }
  return { errors };
};
