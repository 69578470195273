import React from "react";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  Card,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/icons";
import { useQuery } from "@apollo/react-hooks";
import { GET_USER_TASKS } from "../graphql/tasks";
import UserDrawer from "../components/UserDrawer";

interface ITask {
  name: string;
  uid: string;
  projectUid: string;
  priority?: string;
  description?: string;
  completedAt?: string;
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    height: "100%",
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  cardItemContainer: {
    width: "100%",
    borderLeft: `6px solid red`,
  },
  drawerList: {
    width: 250,
  },
}));

const renderPriorityColor = (priority: string | undefined) => {
  switch (priority) {
    case "3":
      return "green";
    case "2":
      return "yellow";
    case "1":
      return "red";
    case "4":
      return "black";
    default:
      return "black";
  }
};

const TaskItem = ({ task }: { task: ITask }) => {
  const classes = useStyles();
  const history = useHistory();

  const taskColor = renderPriorityColor(task.priority)
  return (
    <ListItem button onClick={() => history.push(`doc/${task.uid}`)}>
      <Card className={classes.cardItemContainer} style={{borderLeft: `6px solid ${taskColor}`}}>
        <CardContent>
          <Typography>{task.name}</Typography>
        </CardContent>
      </Card>
    </ListItem>
  );
};

export default function () {
  const [tasks, setTasks] = React.useState<ITask[] | undefined>(undefined);
  const { data } = useQuery(GET_USER_TASKS, {fetchPolicy: "network-only"});
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    // TODO remove any
    const dbTasksWithoutPriority: ITask[] | undefined = data
      ? data.GetUserTasks.edges.map((e: any) => e.node)
      : undefined;

    const dbTasks = dbTasksWithoutPriority
      ? dbTasksWithoutPriority
          .map((task) => {
            const priority = task.priority ? task.priority : "3";
            return { ...task, priority };
          })
          .sort((a, b) => {
            const priorityA = a.priority;
            const priorityB = b.priority;
            if (priorityA < priorityB) return -1;
            if (priorityA > priorityB) return 1;
            return 0;
          })
          .filter((task) => !task.completedAt)
      : undefined;

    if (dbTasks && dbTasks.length > 1) setTasks(dbTasks);
  }, [data]);

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const classes = useStyles();
  return (
    <div className={classes.pageContainer}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge={"start"}
            className={classes.menuButton}
            color={"inherit"}
            aria-label={"menu"}
            onClick={() => setDrawerOpen(true)}
          >
            <Menu />
          </IconButton>
          <Typography variant={"h6"}>Tasks</Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <UserDrawer drawerOpen={drawerOpen} handleClose={handleClose} />
      <List>
        {tasks ? (
          tasks.map((task) => <TaskItem key={task.uid} task={task} />)
        ) : (
          <div />
        )}
      </List>
    </div>
  );
}
