import React from "react";
import { Button } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {COMPLETE_TASK, GET_TASK, START_TASK} from "../graphql/tasks";
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }

}))

export default function () {
  const { taskID } = useParams();
  const history = useHistory();

  const [completeTask] = useMutation(COMPLETE_TASK);
  const [startTask] = useMutation(START_TASK)
  const { data: taskData, refetch } = useQuery(GET_TASK, {variables: {TaskUid: taskID}})

  const startedTask = taskData !== undefined ? taskData.GetTaskByID.startedAt : undefined;

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Button
        onClick={() => {
          startTask({
            variables: { TaskUid: taskID},
          }).then(() => refetch().then());
        }}
        disabled={!!startedTask}
        variant={"contained"}
        type={"submit"}
        color={"primary"}
        size={"large"}
      >
        Start Task
      </Button>
      <div style={{height: 10}}/>
      <Button
        onClick={() => {
          completeTask({
            variables: { TaskUid: taskID},
          }).then(() => history.push('/'));
        }}
        disabled={!startedTask}
        variant={"contained"}
        type={"submit"}
        color={"primary"}
        size={"large"}
      >
        Complete Task
      </Button>
    </div>
  );
}
