import React, { useState, useMemo } from "react";
import { usersFullName } from "../helpers/users";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Input,
} from "@material-ui/core";
import { User } from "../types";

interface Props {
  visible: boolean;
  onCancel: () => void;
  users: User[] | undefined;
  onSelect: (users: User[]) => void;
}

export default function UserSelectModal({
  visible,
  users,
  onCancel,
  onSelect,
}: Props) {
  const [selected, setSelected] = useState<string[]>([]);

  const userLookup = useMemo(
    () =>
      users?.reduce<{ [key: string]: User }>((obj, user) => {
        obj[user.uid] = user;
        return obj;
      }, {}),
    [users]
  );

  function handleOk() {
    if (!selected || !userLookup) {
      return;
    }
    onSelect(selected.map((selectedUid) => userLookup[selectedUid]));
    setSelected([]);
  }

  return (
    <Dialog
      open={visible}
      onClose={() => {
        onCancel();
        setSelected([]);
      }}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Select Users</DialogTitle>
      <DialogContent>
        <Select
          style={{ width: "100%" }}
          multiple
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value as any);
          }}
          input={<Input />}
        >
          {users?.map((u) => (
            <MenuItem key={u.uid} value={u.uid}>
              {usersFullName(u)}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
