import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import { blue } from "@material-ui/core/colors";
import { DocumentNode, Revision } from "../types";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

interface Props {
  document?: DocumentNode;
  open: boolean;
  onOk: (rev: Revision) => void;
  onClose: () => void;
}

export default function RevisionModal({
  onClose,
  onOk,
  open,
  document,
}: Props) {
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Pick a Revision</DialogTitle>
      <List>
        {document?.revisions.map((revision, index) => (
          <ListItem button onClick={() => onOk(revision)} key={revision.uid}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <FolderSpecialIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Revision ${index + 1}`} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
