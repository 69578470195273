import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import messageService from "../services/messaging";
import MessageEditor from "./MessageEditor";
import { TaskMessage, User } from "../types";
import { useTask } from "../services/task";
import { arrayToLookup } from "../helpers";
import { usersFullName } from "../helpers/users";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  chatContainer: {
    display: "flex",
    flex: 1,
    overflow: "auto",
    flexDirection: "column",
  },
  messageCardContainer: {
    margin: 20,
  },
  bottomMessageContainer: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    padding: 20,
    backgroundColor: "#fff",
  },
  inline: {
    display: "inline",
  },
}));

export default function Messaging() {
  const classes = useStyles();
  const { taskID } = useParams();

  const [messages, setMessages] = useState<TaskMessage[]>();
  const { data: task } = useTask(taskID);
  console.log(task);
  const userLookup = useMemo(() => arrayToLookup(task?.users || []), [task]);

  console.log(userLookup);
  useEffect(() => {
    messageService.getTaskMessages(taskID, setMessages);
  }, [taskID]);

  async function handleSubmit(comment: TaskMessage) {
    messageService.addMessage(taskID, comment);
  }

  return (
    <div className={classes.container}>
      <List className={classes.chatContainer}>
        {messages?.map((message) => {
          const author: User | undefined = userLookup[message.author];

          return (
            <ListItem key={message.createdAt} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={usersFullName(author)} src={author?.profileURL} />
              </ListItemAvatar>
              <ListItemText
                primary={usersFullName(author)}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {message.mentions
                        ?.map((uid) => `@${usersFullName(userLookup[uid])}`)
                        .join(", ")}{" "}
                    </Typography>
                    {message.content}
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        })}
      </List>
      <MessageEditor onSubmit={handleSubmit} users={task?.users} />
    </div>
  );
}
