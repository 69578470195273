import { gql } from "apollo-boost";

export const GET_USER_TASKS = gql`
  query {
    GetUserTasks {
      edges {
        node {
          projectUid
          uid
          name
          description
          priority
          completedAt
        }
      }
    }
  }
`;

export const START_TASK = gql`
    mutation StartTask($TaskUid: String!) {
        StartTask(TaskUid: $TaskUid) {
            code
            message
        }
    }
`;

export const COMPLETE_TASK = gql`
    mutation CompleteTask($TaskUid: String!) {
        CompleteTask(TaskUid: $TaskUid) {
            code
            message
        }
    }
`;


export const UPDATE_PRIORITY = gql`
  mutation UpdateTaskPriority($TaskUid: String!, $Priority: String!) {
    UpdateTaskPriority(TaskUid: $TaskUid, Priority: $Priority) {
      uid
      name
      priority
    }
  }
`;

export const GET_TASK = gql`
  query GetTaskByID($TaskUid: String!) {
    GetTaskByID(TaskUid: $TaskUid) {
      uid
      name
      description
      priority
      completedAt
      startedAt
      createdAt
      sharedToAll
      teams {
        uid
        name
      }
      travelers {
        uid
        name
        revisions {
          URL
          URLID
          revision
          createdAt
          documentUid
        }
      }
      workRoles {
        uid
        name
      }
      users {
        uid
        firstName
        lastName
        email
        profileURL
        profileID
      }
    }
  }
`;

export const GET_PROJECT_DOCUMENTS = gql`
  query GetProjectByID($ProjectUid: String!) {
    GetProjectByID(ProjectUid: $ProjectUid) {
      uid
      documents {
        name
        revisions {
          documentUid
          URLID
          URL
        }
      }
      folders {
        uid
        name
        documents {
          uid
          name
          revisions {
            URL
            URLID
          }
        }
      }
    }
  }
`;
