import React from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";

declare global {
  interface Window {
    PSPDFKit: any;
  }
}

//TODO run a check to see if PSPDFKit exists on window b4 rendering
const renderPdfInstance = async (
  documentId: string,
  token: string,
  container: string,
  window: any
) => {
  const toolbarItems = [
    { type: "zoom-out" },
    { type: "zoom-in" },
    { type: "ink" },
    { type: "highlighter" },
  ];

  return await window.PSPDFKit.load({
    container,
    documentId: documentId,
    authPayload: { jwt: token },
    instant: false,
    toolbarItems: toolbarItems,
  });
};

const fetchJwtToken = async (documentId: string) => {
  return await Axios.request({
    url: "https://us-central1-autumn-254618.cloudfunctions.net/getPdfToken",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: { documentId },
  })
    .then((res: any) => res.data)
    .catch((err: any) => console.error(err));
};

const App = () => {
  const inputEl = React.useRef(null);
  const { docID } = useParams();

  React.useEffect(() => {
    async function fetchData(ref: any) {
      if(!docID) return
      const token = await fetchJwtToken(docID);
      const instance = await renderPdfInstance(docID, token, ref, window);
      instance.setViewState((viewState: any) =>
        viewState.set("showToolbar", true)
      );
      instance.setViewState((viewState: any) =>
        viewState.set("enableAnnotationToolbar", true)
      );

      // setPdfInstance(instance);
    }

    fetchData(inputEl.current).then();
    return () => {
      window.PSPDFKit.unload(inputEl.current);
    };
  }, [docID]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div ref={inputEl} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default App;
