import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import logo from "../assets/logo-2.png";
import { AuthContext } from "../App";

export default function () {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loginError, setLoginError] = useState<string | undefined>(undefined);

  const history = useHistory();
  const location = useLocation();

  const handleSignIn = (errors?: unknown) => {
    if (!errors) {
      if (location.pathname === "/login") {
        history.push("/");
      }
      return;
    }
    console.log(errors);
    setLoginError("Your Username or Password is incorrect, please try again");
  };

  return (
    <AuthContext.Consumer>
      {({ signIn }) => (
        <div style={styles.backgroundImage}>
          <div style={styles.contentContainer}>
            <img src={logo} alt="Autumn Logo" height={115} />
            <Box my={1} />
            <Card>
              <CardContent style={styles.cardContainer}>
                <Typography variant={"h6"}>Login</Typography>
                <Typography variant={"subtitle1"}>
                  Access your Autumn account
                </Typography>
                <Box mt={1} />
                <TextField
                  type={"email"}
                  name={"email"}
                  label={"Email Address"}
                  fullWidth
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <Box mt={1} />
                <TextField
                  type={"password"}
                  name={"password"}
                  label={"Password"}
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Box my={2} />
                <Button
                  color={"primary"}
                  variant={"contained"}
                  fullWidth
                  onClick={async () => {
                    const { errors } = await signIn(userName, password);
                    handleSignIn(errors);
                  }}
                >
                  Login
                </Button>
                <Snackbar
                  open={loginError !== undefined}
                  autoHideDuration={6000}
                >
                  <MuiAlert elevation={6} variant="filled" severity={"error"}>
                    {loginError}
                  </MuiAlert>
                </Snackbar>
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </AuthContext.Consumer>
  );
}

const styles = {
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "35vw",
  } as React.CSSProperties,
  backgroundImage: {
    height: "100vh",
    width: "100vw",
    backgroundImage:
      "url(https://images.pexels.com/photos/2381463/pexels-photo-2381463.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  contentContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  } as React.CSSProperties,
};
