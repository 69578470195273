const APP_ENGINE = "https://autumn-prod.uc.r.appspot.com";
// const APP_ENGINE = "http://localhost:8080";

const config = {
  CLOUDINARY_NAME: "autumnmfg",
  CLOUDINARY_API_KEY: "821789297976892",
  CLOUDINARY_UPLOAD_PRESET: "ilgyftgd",
  GRAPHQL: `${APP_ENGINE}/graphql`,
  LOGIN: `${APP_ENGINE}/login`,
  FIRE_BASE: {
    apiKey: "AIzaSyD_MrHQapvyq3JLsckL3hDcDqGp9-k7U2E",
    authDomain: "autumn-chat-service.firebaseapp.com",
    databaseURL: "https://autumn-chat-service.firebaseio.com",
    projectId: "autumn-chat-service",
    storageBucket: "autumn-chat-service.appspot.com",
    messagingSenderId: "231759518410",
    appId: "1:231759518410:web:f84027363b66de40307233",
  },
};

export default config;
