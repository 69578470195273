import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { GET_TASK, GET_PROJECT_DOCUMENTS } from "../graphql/tasks";
import React, { useEffect } from "react";
import Axios, { AxiosResponse } from "axios";
import { Revision } from "../types";

interface IRevision {
  URL: string;
  documentUid: string;
  URLID: string;
}

interface IDocument {
  name: string;
  revisions: IRevision[];
}

interface projectData {
  GetProjectByID: {
    documents: IDocument[] | any;
    uid: string;
    __typename: string;
  };
}

interface taskData {
  GetTaskByID: {
    projectUid: string;
    __typename: string;
  };
}

interface jwtObject {
  id: string;
  jwt: string;
}

const getDocIdFromData = (projectData: projectData | undefined): string[] => {
  return projectData
    ? projectData.GetProjectByID.documents.map(
        (item: IDocument) => item.revisions[0].URLID
      )
    : undefined;
};

const fetchJwtToken = async (documentId: string[]): Promise<jwtObject[]> => {
  return Promise.all(
    documentId.map((docId) => {
      return Axios.request({
        url: "https://us-central1-autumn-254618.cloudfunctions.net/getPdfToken",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: { documentId: docId },
      }).then((res: AxiosResponse<string>) => ({ id: docId, jwt: res.data }));
    })
  );
};

export function useProjectDocs() {
  const [jwtArray, setJwtArray] = React.useState<jwtObject[] | undefined>(
    undefined
  );
  const { taskID } = useParams();
  const { data: taskData } = useQuery(GET_TASK, {
    variables: { BranchUid: "undefined", TaskUid: taskID },
  });

  const projectID =
    taskData !== undefined ? taskData.GetTaskByID.projectUid : undefined;

  const { data: projectData } = useQuery(GET_PROJECT_DOCUMENTS, {
    variables: {
      ProjectUid: projectID,
      BranchUid: "undefined",
    },
  });

  const documents = getDocIdFromData(projectData);

  useEffect(() => {
    async function fetchData() {
      if (documents) {
        const tokens = await fetchJwtToken(documents);
        setJwtArray(tokens);
      }
    }

    fetchData().then();
  }, [documents]);

  return jwtArray;
}
