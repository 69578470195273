import React from "react";
import logo from "../assets/inverse_logo.png";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { ExitToApp, Home } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  img: {
    margin: "20px auto",
  },
  drawerList: {
    width: 250,
  },
}));

export default function ({
  drawerOpen,
  handleClose,
}: {
  drawerOpen: boolean;
  handleClose: () => void;
}) {
  const history = useHistory();

  const classes = useStyles();
  return (
    <Drawer open={drawerOpen} onClose={() => handleClose()}>
      <img src={logo} width={200} alt="Autumn Logo" className={classes.img} />
      <List className={classes.drawerList} onClick={() => handleClose()}>
        <ListItem button onClick={() => history.push("/")}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary={"Tasks"} />
        </ListItem>
        <ListItem button onClick={() => history.push("/login")}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </Drawer>
  );
}
