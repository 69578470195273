import { gql } from "apollo-boost";

export const FETCH_ME = gql`
  query Me {
    Me {
      uid
      email
      firstName
      lastName
      profileID
      profileURL
      branches {
        uid
        name
      }
    }
  }
`;
