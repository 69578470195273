import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Drawer,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import PSPDFKit from "../components/PSPDFKit";
import { Menu } from "@material-ui/icons";
import JobInfo from "../components/JobInfo";
import DocumentPreview from "../components/DocumentPreview";
import Messaging from "../components/Messaging";
import UserDrawer from "../components/UserDrawer";

enum TabOptions {
  Documents,
  Messages,
  Job,
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "90vw",
  },
  drawerContent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  title: {
    flexGrow: 1,
  },
}));

const renderDrawerContent = (
  selectedTab: TabOptions,
  handleDrawerClose: () => void
) => {
  switch (selectedTab) {
    case TabOptions.Documents:
      return <DocumentPreview handleDrawerClose={handleDrawerClose} />;
    case TabOptions.Messages:
      return <Messaging />;
    case TabOptions.Job:
      return <JobInfo />;
  }
};

export default function () {
  const [travelerDrawerOpen, setTravelerDrawerOpen] = React.useState<boolean>(
    true
  );
  const [userDrawerOpen, setUserDrawerOpen] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<TabOptions>(
    TabOptions.Job
  );

  const classes = useStyles();
  return (
    <div className={classes.pageContainer}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge={"start"}
            className={classes.menuButton}
            color={"inherit"}
            aria-label={"menu"}
            onClick={() => setUserDrawerOpen(true)}
          >
            <Menu />
          </IconButton>
          <Typography variant={"h6"} className={classes.title}>
            Documents
          </Typography>
          <IconButton
            color={"inherit"}
            aria-label={"menu"}
            onClick={() => setTravelerDrawerOpen(true)}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <UserDrawer
        drawerOpen={userDrawerOpen}
        handleClose={() => setUserDrawerOpen(false)}
      />
      <Drawer
        anchor={"right"}
        open={travelerDrawerOpen}
        onClose={() => setTravelerDrawerOpen(false)}
      >
        <div className={classes.drawer}>
          <Tabs
            centered
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value)}
            variant={"fullWidth"}
          >
            <Tab label={"Documents"} />
            <Tab label={"Messages"} />
            <Tab label={"Job"} />
          </Tabs>
          {renderDrawerContent(selectedTab, () => setTravelerDrawerOpen(false))}
        </div>
      </Drawer>
      <div style={{ height: "100%", width: "100%" }}>
        <PSPDFKit />
      </div>
    </div>
  );
}
