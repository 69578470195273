import { useQuery } from "@apollo/react-hooks";
import { useMemo } from "react";
import { GET_TASK, GET_PROJECT_DOCUMENTS } from "../graphql/tasks";
import { Task, DocumentFolder, DocumentNode } from "../types";

export function useTask(taskUid: string) {
  const { data, loading, error } = useQuery(GET_TASK, {
    variables: {
      TaskUid: taskUid,
    },
  });

  const task: Task | undefined = useMemo(() => data?.GetTaskByID, [data]);

  return { data: task, loading, error };
}

export function useTaskDocuments(taskUid: string) {
  const { data: taskData } = useQuery(GET_TASK, {
    variables: { TaskUid: taskUid },
  });

  const task: Task = useMemo(() => taskData?.GetTaskByID, [taskData]);

  const { data: projectData } = useQuery(GET_PROJECT_DOCUMENTS, {
    variables: {
      ProjectUid: task?.projectUid,
      BranchUid: "undefined",
    },
    skip: task?.projectUid === undefined,
  });

  const documents = useMemo(() => {
    const projectDocuments = (() => {
      if (!projectData?.GetProjectByID) {
        return;
      }

      const projectDocsAndFolders: {
        uid: string;
        documents: DocumentNode[];
        folders: DocumentFolder[];
      } = projectData?.GetProjectByID;

      const docs = projectDocsAndFolders?.documents || [];

      for (const folder of projectDocsAndFolders?.folders || []) {
        docs.push(...folder.documents);
      }
      return docs;
    })();

    if (!projectDocuments && !task?.travelers) {
      return undefined;
    }
    return [...(task.travelers || []), ...(projectDocuments || [])];
  }, [task, projectData]);

  return documents;
}
