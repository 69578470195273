import config from "../config";

export function arrayToLookup<T>(
  items: Array<T & { uid: string }>
): { [key: string]: T } {
  return items.reduce((obj, item) => {
    obj[item.uid] = item;
    return obj;
  }, {} as { [key: string]: T });
}

export function getCloudinaryThumbnail(url: string, id: string) {
  const opts =
    getFileExtension(url) === "pdf"
      ? `w_${250},h_${250},c_thumb,q_75,f_jpg`
      : `w_${250},h_${250},c_thumb,q_75`;
  return imageUrlFromId(id, opts);
}

export function getFileExtension(url: string) {
  return url && url.split(".").pop()?.split(/\#|\?/)[0];
}

export function imageUrlFromId(id: string, opts: string = "") {
  return `https://res.cloudinary.com/${config.CLOUDINARY_NAME}/image/upload${
    opts ? `/${opts}` : ""
  }/${id}`;
}
