import config from "../config";
import firebase from "firebase";
import { TaskMessage } from "../types";

if (!firebase.apps.length) {
  try {
    firebase.initializeApp(config.FIRE_BASE);
  } catch (err) {}
}

const firebaseDB = firebase.firestore();

const messageService = {
  async addMessage(taskUid: string, message: TaskMessage) {
    await firebaseDB
      .collection("tasks")
      .doc(taskUid)
      .collection("messages")
      .add(message);
  },
  getTaskMessages(
    taskUid: string,
    addMessages: (messages: TaskMessage[]) => void
  ) {
    return firebaseDB
      .collection("tasks")
      .doc(taskUid)
      .collection("messages")
      .orderBy("createdAt", "asc")
      .onSnapshot({}, (querySnapshot) => {
        const messages: TaskMessage[] = [];
        querySnapshot.forEach((doc) => {
          messages.push(doc.data() as any);
        });
        addMessages(messages);
      });
  },
  getMyMentions(
    userUid: string,
    addMentions: (messages: TaskMessage[]) => void
  ) {
    return firebaseDB
      .collectionGroup("messages")
      .where("mentions", "array-contains", userUid)
      .orderBy("createdAt", "desc")
      .onSnapshot({}, (querySnapshot) => {
        const messages: TaskMessage[] = [];
        querySnapshot.forEach((doc) => {
          messages.push(doc.data() as any);
        });
        addMentions(messages);
      });
  },
};

export default messageService;
