import { ApolloError } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { User } from "../types";
import { FETCH_ME } from "../graphql/users";
export function useCurrentUser(): {
  currentUser: User;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { data, loading, error } = useQuery(FETCH_ME);
  const currentUser: User = data?.Me;
  return { currentUser, loading, error };
}
