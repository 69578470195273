import React, { useMemo } from "react";
import { DocumentNode } from "../types";
import { Grid } from "@material-ui/core";
import { getCloudinaryThumbnail } from "../helpers";

interface Props {
  document: DocumentNode;
  onClick: (document: DocumentNode) => void;
}

export default function DocumentThumbnail({ document, onClick }: Props) {
  const thumbnail = useMemo(() => {
    const latest = document.revisions[document.revisions.length - 1];
    return getCloudinaryThumbnail(latest.URL, latest.URLID);
  }, []);

  return (
    <Grid
      item
      onClick={() => {
        onClick(document);
      }}
    >
      <img src={thumbnail} alt="" />
    </Grid>
  );
}
